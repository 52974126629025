@font-face {
    font-family: 'Playtime';
    src: url(../playtime_cyr2.ttf)
}

@font-face {
    font-family: 'Playtime Bold';
    src: url(../playtime_bold.ttf)
}

.navbar {
    background-color: #FFE6A7;
    font-family: Playtime, serif;
    border-bottom: 4px solid #8F8476;
}

.navbar .navbar-nav .nav-link{
    color: #8F8476;
    font-size: 1.5em !important;
    margin-right: 3vmax;
    text-align: center;
}

.navbar-brand {
    font-family: 'Playtime Bold', serif;
    color: #8F8476 !important;
    font-size: 1.6em !important;
    padding-right: 70px;
}

@media (max-width:1000px){
    .navbar-brand{
        font-size: 1.1em !important;
    }
}

.navbar .nav-link:hover {
    color: #ab9e8d;
}


