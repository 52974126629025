@font-face {
    font-family: 'Playtime';
    src: url(../playtime_cyr2.ttf)
}


.start-div {
    width: 55%;
    aspect-ratio: 530 / 277;
    background-image: url("./bg-popup.png");
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center;  
    display: flex;
    justify-content: center;
    align-items: center;
}


.astext {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    font-family: "Playtime", serif;
    color: #8F8476;
    font-size: 2rem ;
}

@media (max-width:1000px){
    .start-div {
        width: 95%;
    }

    .astext {
        font-size: 1.5rem;
        padding: 1rem;
    }
}

.astext:hover {
    color: #ab9e8d;
}