@font-face {
    font-family: 'Playtime';
    src: url(../playtime_cyr2.ttf)
}

@font-face {
    font-family: 'Playtime Bold';
    src: url(../playtime_bold.ttf)
}

.button-choice {
    background-color: #fce5be;
    color: #8F8476;
    border: 4px solid #8F8476; /* Green */
    padding: 10px 20px;
    text-align: center;
    margin-top: 2vmax;
    font-size: 155%;
    font-family: Playtime, serif;
    width: 8em;
    height: 3em;
    border-radius: 8px;
    transition-duration: 0.4s;
}

.div-button{
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.div-list h3{
    font-size: 2em;
    font-family: 'Playtime Bold', serif;
    color: #8F8476;
    text-align: center;
    margin-top: 2vh;
}

.div-list img {
    display: block;
    margin: auto;
    height: 25vmin;
}

.div-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}



.button-choice:hover {
    background-color: #8F8476; /* Green */
    color: #fce5be;
}
