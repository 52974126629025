.contact-div {
    font-family: 'Playtime';
    color: #8F8476;
    font-size: 1.4rem;
    text-align: justify;
    text-justify: inter-word;
    justify-items: center;
    margin-left: 3vw;
}

@media (max-width: 800px) {
    .contact-div{
        font-size: 1.2rem;
    }
}


.flex-box-button {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 3vh;
}

@media (max-width: 1000px) {
    .flex-box-button {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}