@font-face {
    font-family: 'Playtime Bold';
    src: url(../playtime_bold.ttf)
}


.grid-menu {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 2vw;
}

.div-main {
    background-color: #fce5be;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
}

#scrollAbout, #scrollDownload, #scrollSupport{
    font-family: "Playtime Bold", serif;
    color: #8F8476;
    font-size: 2.1rem;
    border-bottom: 4px solid #8F8476;
    padding: 1vh;
    margin-top: 100px;
    display: inline-block;
}

#scrollContact, #scrollBonus {
    font-family: "Playtime Bold", serif;
    color: #8F8476;
    font-size: 2.1rem;
    border-bottom: 4px solid #8F8476;
    padding: 1vh;
    margin-top: 100px;
    display: inline-block;
    margin-left: 3vw;
}

@media (max-width: 1000px) {
    .grid-container {
        display: grid;
        grid-template-rows: auto auto;
        grid-gap: 20px;
    }

    .grid-container-2 {
        display: grid;
        grid-template-rows: auto auto;
        grid-gap: 20px;
        justify-items: center;
        margin-top: 23vh;
        padding-left: 2vw;
    }

    .grid-container-3 {
        margin-top: 5vh;
        margin-left: 3vw;
        display: grid;
        grid-template-rows: auto auto;
        grid-gap: 20px;
        margin-bottom: 5vh;
    }
}

@media (min-width: 1000px)  {
    .grid-container {
        display: grid;
        grid-template-columns: 25% 75%;
        grid-gap: 20px;
    }

    .grid-container-2 {
        display: grid;
        grid-template-columns: 45% 42%;
        grid-gap: 20px;
        justify-items: center;
        margin-top: 23vh;
        padding-left: 2vw;
    }

    .grid-container-3 {
        margin-top: 5vh;
        margin-left: 3vw;
        display: grid;
        grid-template-columns: 47% 43%;
        grid-gap: 20px;
        margin-bottom: 5vh;
    }

    #scrollAbout, #scrollDownload, #scrollSupport, #scrollContact, #scrollBonus {
        font-size: 3rem;
    }
}

.grid-carou {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
}

.button-list {
    margin-top: 2vh;
}

#saki-image {
    width: 83%;
    box-shadow: -1.3vw 1.3vw 0.5vw 0.25vw #8F8476;
    display: block;
}

.image-div {
    display: flex;
    margin-right: 4vw;
    align-items: center;
    justify-content: center;
}





.limit-length {
    width: 15%;
}

.limit-length-2 {
    width: 50%;
}

::-webkit-scrollbar {
    width:0.5vw;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: #8F8476;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #FFE6A7;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #ab9e8d;
}

