@font-face {
    font-family: 'Playtime';
    src: url(../playtime_cyr2.ttf)
}

.about-div {
    font-family: 'Playtime';
    color: #8F8476;
    font-size: 1.5rem;
    text-align: justify;
    text-justify: inter-word;
}

@media (max-width: 800px) {
    .about-div{
        font-size: 1.2rem;
    }
}

.about-div h4 {
    font-size: 1vw;
}

.text-link {
    color: #5b4e3d;
}