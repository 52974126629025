.bonus-div {
    font-family: 'Playtime';
    color: #8F8476;
    font-size: 1.4rem;
    text-align: justify;
    text-justify: inter-word;
    justify-items: center;
    margin-left: 3vw;
}

@media (max-width: 800px) {
    .bonus-div{
        font-size: 1.2rem;
    }
}

.flex-box-button-2 {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 2vmax;

}


@media (max-width: 1000px) {
    .flex-box-button-2 {
        grid-template-columns: 100%;
        grid-gap: 3vmax;
    }
}