@font-face {
    font-family: 'Playtime';
    src: url(../playtime_cyr2.ttf)
}

.download-div {
    font-family: 'Playtime';
    color: #8F8476;
    font-size: 1.4rem;
    text-align: left;
    text-justify: inter-word;
    justify-items: center;

}

.download-div div {
    text-align: center;
    margin-right: 10vw;
}

@media (max-width: 800px) {
    .download-div{
        font-size: 1.2rem;
    }
    .download-div div {
        margin-right: 0px;
    }
}



.button-choice-low-2 {
    background-color: #fce5be;
    color: #8F8476;
    border: 4px solid #8F8476; /* Green */
    padding: 10px 20px;
    text-align: center;
    margin-top: 1vh;
    font-size: 100%;
    font-family: Playtime, serif;
    width: 13em;
    height: 3em;
    border-radius: 8px;
    transition-duration: 0.4s;
}

.button-choice-low-2:hover {
    background-color: #8F8476; /* Green */
    color: #fce5be;
}